<template>
  <div class="select-field">
    <div
      class="select-field__field"
      @click="show = !show"
      :class="{ inactive: show }"
    >
      <span class="select-field__text">{{
        value === "" ? placeholder : current
      }}</span>
    </div>
    <div class="select-field__options" v-show="show">
      <div
        class="select-field__option"
        v-for="(item, index) in items"
        :key="index"
        @click="select(item.value)"
      >
        <span class="select-field__text">{{ item.text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "customSelect",
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    items: {
      type: Array,
      default: () => {
        return [{ text: "", value: "" }];
      },
    },
    placeholder: String,
  },
  data() {
    return {
      show: false,
    };
  },
  created() {
    const onClickOutside = (e) =>
      (this.show = this.$el.contains(e.target) && this.show);
    document.addEventListener("click", onClickOutside);
    this.$on("hook:beforeDestroy", () =>
      document.removeEventListener("click", onClickOutside)
    );
  },
  computed: {
    current() {
      let selected = this.items.find((item) => {
        return item.value === this.value;
      });
      if (selected === undefined) {
        return null;
      }
      return selected.text;
    },
  },
  methods: {
    select(val) {
      this.show = false;
      this.$emit("input", val);
      this.$emit("change");
    },
  },
};
</script>

<style scoped></style>
