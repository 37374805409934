<template>
  <div class="body settings">
    <div class="settings__title-row">
      <div class="settings__title settings-title">
        {{ $t("settings.settings") }}
      </div>
      <div class="settings__mail">{{ mail }}</div>
    </div>

    <settings-passwords></settings-passwords>

    <template v-if="$auth.check({ name: 'admin' })">
      <div class="separator"></div>
      <settings-cities></settings-cities>
    </template>

    <template v-if="$auth.check({ name: 'admin' })">
      <div class="separator"></div>
      <settings-pos id="pos"></settings-pos>
    </template>

    <div
      class="separator"
      v-if="
        $auth.check({ permissions: 'hub_settings' }) ||
        $auth.check({ name: 'admin' })
      "
    ></div>

    <settings-hub
      v-if="
        $auth.check({ permissions: 'hub_settings' }) ||
        $auth.check({ name: 'admin' })
      "
    ></settings-hub>

    <div
      class="separator"
      v-if="
        $auth.check({ permissions: 'hub_settings' }) ||
        $auth.check({ name: 'admin' })
      "
    ></div>
    <settings-upload
      v-if="
        $auth.check({ permissions: 'hub_settings' }) ||
        $auth.check({ name: 'admin' })
      "
    ></settings-upload>

    <!-- <div
      class="separator"
      v-if="
        $auth.check({ permissions: 'hub_settings' }) ||
        $auth.check({ name: 'admin' })
      "
    ></div> -->
    <!-- <settings-accesses
      v-if="
        $auth.check({ permissions: 'hub_settings' }) ||
        $auth.check({ name: 'admin' })
      "
    ></settings-accesses> -->
  </div>
</template>

<script>
import SettingsPasswords from "@/components/components/settingsPasswords";
import SettingsHub from "@/components/components/settingsHub";
import SettingsUpload from "@/components/components/settingsUpload";
// import SettingsAccesses from "@/components/components/settingsAccesses";
import SettingsCities from "@/components/components/settingsCities";
import SettingsPos from "@/components/components/settingsPos";

export default {
  name: "settings",
  components: {
    SettingsUpload,
    SettingsHub,
    SettingsPasswords,
    // SettingsAccesses,
    SettingsCities,
    SettingsPos,
  },
  data() {
    return {};
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$socket.readyState === 1) {
        vm.$socket.sendObj({
          route: to.name,
          token: localStorage.getItem("access_token"),
        });
      }
    });
  },
  computed: {
    mail() {
      let user = this.$auth.user();
      return user["login"];
    },
  },
};
</script>

<style scoped>
.hidden {
  display: none;
}
</style>
