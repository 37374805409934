<template>
  <div>
    <form @submit.prevent="apply">
      <div class="settings__row">
        <div class="select-container">
          <span class="settings__pass-title settings-title">{{
            $t("settings.city.title")
          }}</span>
          <div>{{ $t("settings.city.city") }}</div>
          <multi-autocomplete
            v-on:selected="update_city_select"
            v-on:deleted="remove_chip_from_select"
            @filter="search_input"
            class="settings-cities__field"
            :value="city.id"
            id="city_select"
            ref="city_id"
            :options="all_cities"
            :selectedOptions="selected_cities"
            :favoriteOptions="favorite_cities"
            :clear="clear"
            v-on:reset="reset_clear"
            :placeholder="$t('settings.city.text_field_hint')"
          ></multi-autocomplete>
        </div>
        <div class="chip-container">
          <template>
            <div
              class="chip"
              :class="chipcolor(city.action)"
              v-for="city in cities"
              :key="city.id"
            >
              <span class="chip--remove" @click="remove_chip(city)">X</span>
              <span>{{ city.name }}</span>
            </div>
          </template>
        </div>
      </div>
      <div>
        <p>{{ render_count_string() }}</p>
      </div>
      <div class="settings__row settings__btn-row">
        <loader v-if="info.loader"></loader>
        <custom-btn
          v-else
          class="button button_theme_blue"
          type="submit"
          :disabled="cities.some((x) => x.error)"
          >{{ $t("settings.city.apply") }}</custom-btn
        >
        <div
          class="settings__city-status"
          v-if="check_status.status !== 200 && check_status.status > 0"
        >
          {{ render_status_check() }}
          <span
            v-for="(message, index) in create_messages()"
            :key="message.name"
          >
            <span
              class="settings__city-status--link"
              @click="push_link(message.link)"
            >
              {{ message.name }},
            </span>
            <span v-if="index !== create_messages().length - 1">,</span>
          </span>
          <span>.</span>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import CustomBtn from "@/components/recurringComponents/customBtn";
import Loader from "@/components/recurringComponents/loader";
import MultiAutocomplete from "../recurringComponents/multiautocomplete.vue";
import { cities } from "../../helpers/city_favorites_list";

export default {
  name: "settingsCities",
  components: { Loader, CustomBtn, MultiAutocomplete },
  data() {
    return {
      info: {
        error: false,
        type: "",
        loader: false,
        status: [],
      },
      change_response: null,
      timeout: null,
      clear: false,
      city: "",
      debounce_input: () => {},
    };
  },
  created() {
    this.$store.dispatch("load_all_cities");
    this.$store.dispatch("load_cities");
  },
  computed: {
    cities() {
      return this.$store.getters.CITIES.map((city) => {
        return {
          value: city.id,
          uuid: city.id,
          action: city.action,
          favorite: city.favorite,
          error: city.error,
          area: city.area,
          region: city.region,
          checked: city.checked ?? false,
          name: `${city.name}`,
        };
      });
    },
    all_cities() {
      return this.$store.getters.ALL_CITIES.map((city) => {
        return {
          value: city.id,
          uuid: city.id,
          action: this.cities.find((x) => x?.value === city.id)?.action,
          area: city.area,
          region: city.region,
          checked:
            this.cities.find((x) => x?.value === city.id)?.checked ?? false,
          name: `${city.name}`,
        };
      });
    },
    selected_cities() {
      return this.$store.getters.SELECTED_CITIES.map((city) => {
        return {
          value: city.id,
          uuid: city.global_id,
          action: this.cities.find((x) => x?.value === city.global_id)?.action,
          area: city.area,
          region: city.region,
          checked:
            this.cities.find((x) => x?.value === city.global_id)?.checked ??
            true,
          name: `${city.name}`,
        };
      });
    },
    favorite_cities() {
      return this.$store.getters.FAVORITE_CITIES.map((city) => {
        return {
          value: city.id,
          uuid: city.id,
          favorite: city.favorite,
          action: this.cities.find((x) => x?.id === city.id)?.action ?? "",
          area: city.area,
          region: city.region,
          checked: this.cities.find((x) => x?.id === city.id)?.checked ?? false,
          name: `${city.name}`,
        };
      });
    },
    check_status() {
      return this.$store.getters.CITY_CHECK_STATUS;
    },
  },
  methods: {
    render_status_check() {
      return this.check_status.status !== 200 && this.check_status.status > 0
        ? this.$i18n.t("settings.city.check.general")
        : "";
    },
    create_messages() {
      const { details } = this.check_status;
      let links = [];
      if (details?.hub > 0)
        links.push({
          name: this.$i18n.t("settings.city.check.hubs"),
          link: "/hubs",
        });

      if (details?.pos > 0)
        links.push({
          name: this.$i18n.t("settings.city.check.poses"),
          link: "#pos",
        });

      if (details?.employee > 0)
        links.push({
          name: this.$i18n.t("settings.city.check.employees"),
          link: "/team",
        });

      if (details?.account > 0)
        links.push({
          name: this.$i18n.t("settings.city.check.accounts"),
          link: "/accounts",
        });
      return links;
    },
    push_link(link) {
      if (link.includes("#")) {
        document.getElementById("pos").scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      } else {
        this.$router.push(link);
      }
    },
    clear_message() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.$store.dispatch("clear_city_check_action");
      }, 5000);
    },
    apply() {
      let currentCitiesData = [...this.cities].filter((x) => x.action);
      currentCitiesData = currentCitiesData.map((item) => {
        return {
          action: item.action,
          request_id: `${Math.floor(Math.random() * 20000)}_${item.name}`,
          data: {
            id: item.value,
            region: item.region,
            area: item.area,
            name: item.name,
          },
        };
      });
      if (currentCitiesData.length > 0) {
        this.$store
          .dispatch("change_cities", currentCitiesData)
          .then((response) => {
            let { data } = response.data;
            data = data.filter((x) => x.status !== 200);
            setTimeout(() => {
              this.update_cities_status(data);
            }, 80);
            this.info.error = false;
            this.info.type = "success";
          })
          .catch(() => {
            this.info.error = true;
            this.info.type = "success";
          })
          .finally(this.clear_message);
      }
    },
    update_cities_status(data) {
      data.forEach((item) => {
        if (item.action === "delete") {
          this.$store.dispatch("update_city_action", {
            value: item.data.id,
            action: "delete",
            checked: false,
          });
        }
        if (item.action === "create") {
          this.$store.dispatch("update_city_action", {
            value: item.data.id,
            action: "create",
            checked: true,
          });
        }
      });
    },
    update_city_select(value) {
      this.city = value;
      this.error_text = "";
      if (value.value) {
        this.$store.dispatch("add_city", value);
        this.$store.dispatch("add_city_to_selected", value);
        this.clear_message();
      }
    },
    reset_clear() {
      this.clear = false;
    },
    search_input(value) {
      if (this.debounce_input) {
        clearTimeout(this.debounce_input);
      }
      this.debounce_input = setTimeout(() => {
        this.$store.dispatch("load_all_cities", value);
      }, [400]);
      if (this.debounce_input instanceof Function) this.debounce_input(value);
    },
    chipcolor(action) {
      switch (action) {
        case "create":
          return "chip_color--create";
        case "delete":
          return "chip_color--delete";
        default:
          return "chip_color--saved";
      }
    },
    remove_chip_from_select(item) {
      let selected_city = this.cities.find((x) => x.value === item.value);
      this.remove_chip(selected_city);
    },
    remove_chip(item) {
      switch (item.action) {
        case "create":
          return this.remove_chip_create_handler(item);
        case "delete":
          return this.remove_chip_delete_handler(item);
        default:
          return this.remove_chip_default_handler(item);
      }
    },
    remove_chip_create_handler(item) {
      this.$store.dispatch("remove_city_from_selected", item);
      this.handle_favorite(item);
      this.$store.dispatch("remove_city", item);
    },
    remove_chip_delete_handler(item) {
      this.$store.dispatch("add_city_to_selected", item);
      this.$store.dispatch("remove_city_from_all", item);
      this.$store.dispatch("update_city_action", {
        value: item.value,
        action: undefined,
        checked: true,
        error: false,
      });
    },
    remove_chip_default_handler(item) {
      this.$store.dispatch("remove_city_from_selected", item);
      this.handle_favorite(item);
      this.clear_message();
      this.$store.dispatch("update_city_action", {
        value: item.value,
        action: "delete",
        checked: false,
      });
    },
    handle_favorite(item) {
      if (cities.includes(item.name) | item.favorite) {
        this.$store.dispatch("add_city_to_favorites", item);
      } else {
        this.$store.dispatch("add_city_to_all", item);
      }
    },
    render_count(action) {
      if (this.cities.some((x) => x.action === action)) {
        const cities_count = this.cities?.filter(
          (x) => x.action === action
        )?.length;
        let last_digit = (cities_count + "").split("").pop();
        let count_end = "";
        switch (last_digit) {
          case "1":
            count_end = this.$i18n.t("settings.city.endings.single");
            break;
          case "2":
          case "3":
          case "4":
            count_end = this.$i18n.t("settings.city.endings.multiple_a");
            break;
          case "5":
          case "6":
          case "7":
          case "8":
          case "9":
          case "0":
            count_end = this.$i18n.t("settings.city.endings.multiple_b");
            break;
        }

        if (cities_count >= 11 && cities_count < 15) {
          count_end = this.$i18n.t("settings.city.endings.multiple_b");
        }
        let action_string = this.$i18n.t(`settings.city.${action}_count`);
        return `${action_string} ${cities_count} ${count_end}`;
      }
      return "";
    },
    render_count_string() {
      let string_array = [];
      if (this.cities.some((x) => x.action)) {
        if (this.cities.some((x) => x.action === "delete")) {
          string_array.push(this.render_count("delete"));
        }
        if (this.cities.some((x) => x.action === "create")) {
          string_array.push(this.render_count("create"));
        }
        return `${this.$i18n.t("settings.city.warning")} 
        ${string_array.join(" " + this.$i18n.t("settings.city.and") + " ")}!`;
      }
    },
  },
};
</script>

<style scoped>
.settings-cities__field {
  margin-bottom: 30px;
}
.settings__city-status {
  color: red;
}
.settings__city-status--link {
  text-decoration: underline;
  cursor: pointer;
}
.chip {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-top: 10px;
  margin-left: 10px;
  padding: 12px;
  border-radius: 30px;
}
.chip_color--saved {
  background-color: #d4deff;
}
.chip_color--create {
  background-color: #b3eda0;
}
.chip_color--delete {
  background-color: #d7d7d7;
}
.chip--remove {
  cursor: pointer;
  font-size: 15px;
  margin-right: 5px;
}
.chip-container {
  display: flex;
  width: 70%;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.select-container {
  min-width: 344px;
  width: 30%;
}
</style>
