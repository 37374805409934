<template>
  <div class="change-block">
    <settings-pos-text-fields
      ref="fields"
      :for_table="true"
      :edit="true"
    ></settings-pos-text-fields>

    <div
      v-if="info.loader"
      class="change-block__btn-block change-block__btn-block_accounts"
    >
      <div class="change-block__info-block">
        <loader></loader>
        <span
          class="text-fields-block__info-text text_color_green"
          :class="{ text_color_red: info.error }"
          >{{ info_text }}</span
        >
      </div>
    </div>
    <div
      v-else
      class="change-block__btn-block change-block__btn-block_accounts"
    >
      <div>
        <custom-btn
          class="button button_theme_blue"
          @click="edit_pos"
          :disabled="disable_save_button()"
          >{{ $t("accounts.change_block.save") }}</custom-btn
        >
        <simple-button
          :title="$t('accounts.change_block.cancel')"
          @click="$emit('cancel')"
        ></simple-button>
        <span
          class="text_color_green"
          :class="{ text_color_red: info.error }"
          >{{ info_text }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import CustomBtn from "@/components/recurringComponents/customBtn";
import SimpleButton from "@/components/recurringComponents/simpleButton";
import Loader from "@/components/recurringComponents/loader";
import SettingsPosTextFields from "@/components/components/settingsPosTextFields";

export default {
  name: "settingsPosChangeBlock",
  props: ["value"],
  components: {
    Loader,
    SettingsPosTextFields,
    SimpleButton,
    CustomBtn,
  },
  created() {
    const currentItem = {
      id: this.value.id,
      action: this.value.action,
      address: this.value.address,
      name: this.value.name,
      city: {
        id: this.value.city_id,
        name: this.value.city_name,
      },
    };
    this.$store.dispatch("save_original_edit_state", this.value);
    this.$store.dispatch("update_edit_pos", currentItem);
  },
  data() {
    return {
      timeout: null,
      info: {
        error: false,
        type: "",
        loader: false,
      },
    };
  },
  computed: {
    edit_pos_item() {
      return this.$store.getters.EDIT_POS;
    },
    info_text() {
      switch (this.info.type) {
        case "required":
          return this.$i18n.t("errors.required");
        case "invalid_range":
          return this.$i18n.t("errors.invalid_range");
        case "no_format":
          return this.$i18n.t("errors.format");
        case "no_password":
          return this.$i18n.t("errors.value");
        case "progress":
          return this.$i18n.t("applications.loader.progress_edit");
        case "failed":
          return this.$i18n.t("applications.loader.failed_edit");
        case "success":
          return this.$i18n.t("applications.loader.success_edit");
        default:
          return "";
      }
    },
  },
  methods: {
    disable_save_button() {
      if (!this.$store.getters.EDIT_POS.city.id) {
        return true;
      }

      return false;
    },
    validate() {
      this.info.error = false;
      this.info.type = "";
      for (let key in this.$refs) {
        let valid = this.$refs[key].validate();
        if (!valid.valid) {
          this.info.error = true;
          this.info.type = valid.error;
        }
      }
      return !this.info.error;
    },
    edit_pos() {
      this.$store
        .dispatch("check_pos_action", {
          data: this.$store.getters.EDIT_POS,
          action: "update",
        })
        .then((response) => {
          const { data } = response.data;
          if (data.status === 200) {
            let updateItem = [
              {
                action: "update",
                request_id: `${Math.floor(Math.random() * 90000)}_${
                  this.edit_pos_item.name
                }`,
                data: {
                  id: this.edit_pos_item.id,
                  address: this.edit_pos_item.address,
                  name: this.edit_pos_item.name,
                  city: {
                    id: this.edit_pos_item.city.id,
                  },
                },
              },
            ];
            this.$store.dispatch("add_poses", updateItem).then(() => {});
          } else {
            this.$store.dispatch("restore_original_edit_state");
          }
        });
      this.clear_message();
    },
    clear_message() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.$store.dispatch("clear_pos_check_action");
      }, 5000);
    },
  },
};
</script>

<style scoped></style>
