<template>
  <div>
    <div class="settings__upload-title settings-title">
      {{ $t("settings.upload.title") }}
    </div>
    <div class="settings__row">
      <div class="settings__input_row">
        <div class="settings__upload-row">
          <custom-select
            v-model="type"
            :placeholder="$t('settings.upload.mode')"
            :items="mode_list"
            class="select"
          ></custom-select>
        </div>
        <div class="settings__upload-row" v-if="type === 'yandex'">
          <custom-text-field
            :placeholder="$t('settings.upload.token')"
            v-model="yandex.token"
            class="field"
          ></custom-text-field>
        </div>
        <!-- <setting-amazon
          v-model="amazon"
          v-if="type === 'amazon'"
        ></setting-amazon> -->
        <setting-sftp v-model="sftp" v-if="type === 'sftp'"></setting-sftp>
        <!-- <setting-ftps v-model="ftps" v-if="type === 'ftps'"></setting-ftps> -->
        <!--        <setting-tinkoff-->
        <!--          v-model="tinkoff"-->
        <!--          v-if="type === 'tinkoff'"-->
        <!--        ></setting-tinkoff>-->
        <div class="settings__row"></div>
        <!--    <div class="settings__upload-button">-->
        <div class="settings__btn-row settings__btn-row_upload">
          <loader v-if="info.loader" class="loader-size"></loader>
          <custom-btn
            v-else
            class="button button_theme_blue"
            :disabled="!type"
            @click="apply"
            >{{ $t("settings.upload.apply") }}</custom-btn
          >
          <span
            class="text-fields-block__info-text text_color_green info-text"
            :class="{ text_color_red: info.error }"
            >{{ info_text }}</span
          >
        </div>
      </div>
      <check-block
        class="settings__check-block-dps"
        v-model="handlers.vad"
        :hint="true"
        :hint_text="$t('settings.upload.hint.data_processing_enable')"
        :title="$t('settings.upload.data_processing_enable')"
        :on_text="$t('settings.upload.on')"
        :off_text="$t('settings.upload.off')"
      ></check-block>
      <check-block
        class="settings__check-block-dps"
        v-model="handlers.kws"
        :class="{ hidden: !handlers.vad }"
        :hint_text="$t('settings.upload.hint.dialog_frame_spotting')"
        :title="$t('settings.upload.dialog_frame_spotting')"
        :on_text="$t('settings.upload.on')"
        :off_text="$t('settings.upload.off')"
      ></check-block>
      <custom-check-block
        :options="kws_modes"
        class="settings__check-block-dps"
        v-model="handlers.kws_mode"
        :class="{ hidden: !handlers.vad }"
        :title="$t('settings.upload.upload_volume')"
        :hint="true"
        :hint_text="$t('settings.upload.hint.upload_volume')"
      ></custom-check-block>
    </div>
  </div>
</template>

<script>
import CustomSelect from "@/components/recurringComponents/customSelect";
import CustomTextField from "@/components/recurringComponents/customTextField";
// import SettingAmazon from "@/components/components/settingAmazon";
import SettingSftp from "@/components/components/settingSftp";
// import SettingFtps from "@/components/components/settingFtps";
import CustomBtn from "@/components/recurringComponents/customBtn";
import Loader from "@/components/recurringComponents/loader";
// import SettingTinkoff from "@/components/components/settingTinkoff";
import CheckBlock from "@/components/recurringComponents/checkBlock";
import CustomCheckBlock from "@/components/recurringComponents/customCheckBlock";
export default {
  name: "settingsUpload",
  components: {
    CustomCheckBlock,
    // SettingTinkoff,
    CheckBlock,
    Loader,
    CustomBtn,
    // SettingFtps,
    SettingSftp,
    // SettingAmazon,
    CustomTextField,
    CustomSelect,
  },
  data() {
    return {
      info: {
        error: false,
        type: "",
        loader: false,
      },
      mode_list: [
        // { text: "Tinkoff", value: "tinkoff" },
        { text: "Yandex.Disk", value: "yandex" },
        // { text: "Amazon S3", value: "amazon" },
        { text: "SFTP", value: "sftp" },
        // { text: "FTPS", value: "ftps" },
      ],
      type: "",
      amazon: {
        access_key: "",
        secret_key: "",
        bucket_name: "",
        region_name: "",
      },
      yandex: { token: "" },
      sftp: { host: "", port: 22, username: "", password: "" },
      ftps: { host: "", port: 23, username: "", password: "" },
      tinkoff: { token: "" },
      handlers: {
        vad: false,
        kws: false,
        kws_mode: "all",
      },
      kws_modes: [
        {
          text: this.$i18n.t("settings.upload.upload_volume_options.all"),
          value: "all",
        },
        {
          text: this.$i18n.t("settings.upload.upload_volume_options.optimal"),
          value: "optimal",
        },
        {
          text: this.$i18n.t("settings.upload.upload_volume_options.minimal"),
          value: "minimal",
        },
      ],
    };
  },
  computed: {
    info_text() {
      switch (this.info.type) {
        case "progress":
          return this.$i18n.t("settings.hub.loader.progress");
        case "failed":
          return this.$i18n.t("settings.hub.loader.failed");
        case "success":
          return this.$i18n.t("settings.hub.loader.success");
        default:
          return "";
      }
    },
  },
  watch: {
    "$i18n.locale"() {
      for (let item of this.kws_modes) {
        item.text = this.$t(
          `settings.upload.upload_volume_options.${item.value}`
        );
      }
    },
  },
  methods: {
    apply() {
      if (this.validate()) {
        this.info.loader = true;
        this.info.type = "progress";
        let upload = {
          type: this.type,
          params: this._data[this.type],
          handlers: this.handlers,
        };
        this.$store
          .dispatch("apply_hub_upload", upload)
          .then(() => {
            this.info.type = "success";
          })
          .catch(() => {
            this.info.error = true;
            this.info.type = "failed";
          })
          .finally(() => {
            this.info.loader = false;
            setTimeout(() => {
              this.info.type = "";
              this.info.error = false;
            }, [3000]);
          });
      }
    },
    validate() {
      this.info.error = false;
      this.info.type = "";

      for (let key in this.$refs) {
        let valid = this.$refs[key].validate();
        if (!valid.valid) {
          this.info.error = true;
          this.info.type = valid.error;
        }
      }

      return !this.info.error;
    },
  },
};
</script>

<style scoped>
.settings__input_row {
  margin-right: 40px;
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.info-text {
  position: absolute;
  margin-left: 175px;
}

.loader-size {
  height: 44px;
}
</style>
