<template>
  <div class="settings__row settings__row--pos">
    <div>
      <span :class="{ invalid_field: this.invalid.city_id }">{{
        $t("settings.pos.pos")
      }}</span>
      <custom-text-field
        ref="name"
        @change="check_input()"
        @input="change_input"
        :value="new_pos.name"
        :v-model="new_pos.name"
        :placeholder="$t('settings.pos.new_pos')"
        class="settings__pos-field"
      ></custom-text-field>
    </div>
    <div>
      <div
        v-if="edit || new_pos.name"
        class="text-fields-block__element text-fields-block__element_pos"
      >
        <span :class="{ invalid_field: this.invalid.city_id }">{{
          $t("settings.pos.city")
        }}</span>
        <autocomplete
          v-on:selected="update_city_select"
          :value="new_pos.city.id"
          :rules="[rules.require]"
          id="city_select"
          class="text-fields-block__field pos-autocomplete"
          ref="city"
          :options="cities"
          :clear="clear"
          v-on:reset="reset_clear"
          :placeholder="$t('settings.pos.city')"
        ></autocomplete>
      </div>
    </div>
    <div v-if="new_pos.city.id && !edit">
      <custom-btn
        v-if="!edit"
        :disabled="check_duplicates()"
        class="button button_theme_blue"
        @click="add_pos"
      >
        {{ $t("settings.pos.add") }}
      </custom-btn>
    </div>
  </div>
</template>
<script>
import Autocomplete from "@/components/recurringComponents/autocomplete";
import CustomTextField from "@/components/recurringComponents/customTextField";
import CustomBtn from "@/components/recurringComponents/customBtn";
import rules from "@/rules";

export default {
  name: "settingsPosTextFields",
  props: {
    for_table: Boolean,
    edit: {
      type: Boolean,
      default: false,
    },
  },
  components: { CustomBtn, CustomTextField, Autocomplete },
  data() {
    return {
      clear: false,
      rules: rules,
      city_id: 0,
      timeout: null,
      info: {
        error: false,
        type: "",
        text: "",
        loader: false,
      },
      invalid: {
        city_id: false,
      },
    };
  },
  computed: {
    new_pos: {
      get() {
        if (this.edit) {
          return this.$store.getters.EDIT_POS;
        }
        return this.$store.getters.NEW_POS;
      },
      set(value) {
        if (this.edit) {
          this.$store.dispatch("update_edit_pos", value);
        }
        this.$store.dispatch("update_new_pos", value);
      },
    },
    cities() {
      return this.$store.getters.CITIES.filter((x) => !x.action).map((city) => {
        return {
          value: city.id,
          uuid: city.id,
          text: `${city.name}`,
        };
      });
    },
  },
  destroyed() {
    this.clear_add_fields();
  },
  methods: {
    change_input(value) {
      this.new_pos.name = value;
      if (this.edit) {
        this.$store.dispatch("update_edit_pos", this.new_pos);
      }
    },
    check_input() {
      if (!this.new_pos.name && !this.edit) {
        this.clear_add_fields();
      }
    },
    check_duplicates() {
      return this.$store.getters.ALL_POSES.some(
        (x) =>
          x.name === this.new_pos.name && x.city.id === this.new_pos.city.id
      );
    },
    add_pos() {
      const data = {
        id: Math.floor(Math.random() * 20000),
        action: "create",
        name: this.new_pos.name,
        address: this.new_pos.address,
        city: {
          id: this.new_pos.city.id,
          name: this.new_pos.city.name,
        },
      };
      this.$store.dispatch("check_pos_action", {
        data: data,
        action: "create",
      });
      this.clear_message();
      this.$store.dispatch("add_pos", data);
      this.clear_add_fields();
    },
    clear_message() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.$store.dispatch("clear_pos_check_action");
      }, 5000);
    },
    clear_add_fields() {
      this.new_pos.name = "";
      this.new_pos.city.id = 0;
      this.new_pos.city.name = "";
    },
    reset_clear() {
      this.clear = false;
    },
    update_city_select(value) {
      this.new_pos.city = {
        id: value,
        name: this.cities.find((x) => x.value == value)?.text,
      };
      if (this.edit) {
        this.$store.dispatch("update_edit_pos", this.new_pos);
      }
      this.error_text = "";
    },
  },
};
</script>
<style scoped>
.settings__row--pos {
  justify-content: flex-start;
  align-items: flex-end;
  gap: 20px;
  margin-bottom: 25px;
}

.text-fields-block__element_pos {
  width: 350px;
}

.settings__pos-field {
  margin-top: 5px;
}

.pos-autocomplete {
  padding-right: 40px;
}
</style>
