<template>
  <div>
    <div>
      <span class="settings__pass-title settings-title">{{
        $t("settings.pos.title")
      }}</span>
      <settings-pos-text-fields :for_table="false"></settings-pos-text-fields>
      <div class="settings__row">
        <settings-pos-table
          :header_items="headers"
          :items="poses"
          @delete="delete_item"
          @change_sorted="change_sorted"
        ></settings-pos-table>
      </div>
      <div>
        <p>{{ render_count_string() }}</p>
      </div>
      <div class="settings__btn-row settings__btn-pos">
        <loader v-if="info.loader"></loader>
        <custom-btn
          v-else
          class="button button_theme_blue"
          :disabled="poses.some((x) => x.error)"
          @click="apply"
          >{{ $t("settings.pos.apply") }}</custom-btn
        >
        <span
          class="text-fields-block__info-text text_color_green"
          :class="{ text_color_red: info.error }"
          >{{ info_text }}</span
        >
        <div
          class="settings__pos-status"
          v-if="check_status.status !== 200 && check_status.status > 0"
        >
          {{ render_status_check() }}
          <span
            v-for="(message, index) in create_messages()"
            :key="message.name"
          >
            <span
              class="settings__pos-status--link"
              @click="push_link(message.link)"
            >
              {{ message.name }}
            </span>
            <span v-if="index !== create_messages().length - 1">,</span>
          </span>
          <span>.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/recurringComponents/loader";
import SettingsPosTable from "./settingsPosTable.vue";
import settingsPosTextFields from "./settingsPosTextFields.vue";
import CustomBtn from "@/components/recurringComponents/customBtn";
import rules from "@/rules";

export default {
  name: "settingsPos",
  components: {
    Loader,
    SettingsPosTable,
    settingsPosTextFields,
    CustomBtn,
  },
  data() {
    return {
      clear: false,
      rules: rules,
      city_id: 0,
      timeout: null,
      info: {
        error: false,
        type: "",
        text: "",
        loader: false,
      },
      invalid: {
        city_id: false,
      },
      headers: [
        {
          name: "name",
          title: this.$i18n.t("settings.pos.headers.name"),
          sorted: 0,
        },
        {
          name: "city_name",
          title: this.$i18n.t("settings.pos.headers.city_name"),
          sorted: 0,
        },
      ],
    };
  },
  watch: {
    "$i18n.locale"() {
      for (let item of this.headers) {
        item.title = this.$t(`settings.pos.headers.${item.name}`);
      }
    },
  },
  created() {
    this.$store.dispatch("load_all_poses");
  },
  computed: {
    poses() {
      return this.$store.getters.ALL_POSES.map((pos) => {
        return {
          id: pos.id,
          action: pos.action,
          error: pos.error,
          name: pos.name,
          address: pos.address,
          city: {
            id: pos.city.id,
            name: pos.city.name,
          },
          city_id: pos.city.id,
          city_name: pos.city.name,
        };
      });
    },
    check_status() {
      return this.$store.getters.POS_CHECK_STATUS;
    },
    info_text() {
      switch (this.info.type) {
        case "required":
          return this.$i18n.t("errors.required");
        case "confirm":
          return this.$i18n.t("settings.password.loader.confirm");
        case "progress":
          return this.$i18n.t("settings.password.loader.progress");
        case "failed":
          return this.$i18n.t("settings.password.loader.failed");
        case "success":
          return this.$i18n.t("settings.password.loader.success");
        default:
          return "";
      }
    },
  },
  methods: {
    render_status_check() {
      return this.check_status.status !== 200 && this.check_status.status > 0
        ? this.$i18n.t("settings.pos.check.general")
        : "";
    },
    create_messages() {
      const { details } = this.check_status;
      let links = [];
      if (details?.hub > 0)
        links.push({
          name: this.$i18n.t("settings.pos.check.hubs"),
          link: "/hubs",
        });
      if (details?.application > 0)
        links.push({
          name: this.$i18n.t("settings.pos.check.applications"),
          link: "/applications",
        });
      if (details.pos > 0)
        links.push({
          name: this.$i18n.t("settings.pos.check.poses"),
          link: "#pos",
        });
      if (details.employee > 0)
        links.push({
          name: this.$i18n.t("settings.pos.check.employees"),
          link: "/team",
        });
      if (details.account > 0)
        links.push({
          name: this.$i18n.t("settings.pos.check.accounts"),
          link: "/accounts",
        });

      return links;
    },
    push_link(link) {
      if (link.includes("#")) {
        document.getElementById("pos").scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      } else {
        this.$router.push(link);
      }
    },
    clear_message() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.$store.dispatch("clear_pos_check_action");
      }, 5000);
    },
    apply() {
      const currentData = this.$store.getters.ALL_POSES.filter(
        (x) => x.action
      ).map((pos) => {
        return {
          action: pos.action,
          request_id: `${Math.floor(Math.random() * 90000)}_${pos.name}`,
          data: {
            id: pos.action !== "create" ? pos.id : 0,
            name: pos.name,
            address: pos.address,
            city: {
              id: pos.city.id,
            },
          },
        };
      });

      this.$store.dispatch("add_poses", currentData).then((response) => {
        let { data } = response.data;
        data = data.filter((x) => x.status !== 200);
        setTimeout(() => {
          this.update_poses_status(data);
        }, 80);
      });
    },
    update_poses_status(data) {
      data.forEach((item) => {
        if (item.action === "delete") {
          this.$store.dispatch("update_pos_action", {
            value: item.data.id,
            action: "delete",
          });
        }
        if (item.action === "create") {
          this.$store.dispatch("update_pos_action", {
            value: item.data.id,
            action: "create",
          });
        }
      });
    },
    delete_item(item) {
      if (this.$store.state.original_edit_pos.id) {
        this.$store.dispatch("restore_original_edit_state");
      }
      if (item.action !== "delete" && item.action !== "create") {
        this.$store.dispatch("check_pos_action", {
          data: item,
          action: "delete",
        });
        this.clear_message();
      }
      this.$store.dispatch("remove_pos", item);
    },
    validate() {
      this.info.error = false;
      this.info.type = "";

      for (let key in this.$refs) {
        let valid = this.$refs[key].validate();
        if (!valid.valid) {
          this.info.error = true;
          this.info.type = valid.error;
        }
      }

      return !this.info.error;
    },
    change_sorted(item) {
      let change_item = this.headers.find((it) => it.name === item);
      let sort = change_item.sorted;

      this.headers.forEach((item) => (item.sorted = 0));

      if (sort !== 1) change_item.sorted = 1;
      else change_item.sorted = -1;

      if (change_item.sorted !== 0) {
        this.poses.sort((a, b) => {
          if (a[item] > b[item]) return change_item.sorted;
          if (a[item] === b[item]) return 0;
          if (a[item] < b[item]) return -change_item.sorted;
        });
      }
    },
    render_count(action) {
      if (this.poses.some((x) => x.action === action)) {
        const poses_count = this.poses?.filter(
          (x) => x.action === action
        )?.length;
        let last_digit = (poses_count + "").split("").pop();
        let count_end = "";
        switch (last_digit) {
          case "1":
            count_end = this.$i18n.t("settings.pos.endings.single");
            break;
          case "2":
          case "3":
          case "4":
            count_end = this.$i18n.t("settings.pos.endings.multiple_a");
            break;
          case "5":
          case "6":
          case "7":
          case "8":
          case "9":
          case "0":
            count_end = this.$i18n.t("settings.pos.endings.multiple_b");
            break;
        }

        if (poses_count >= 11 && poses_count < 15) {
          count_end = this.$i18n.t("settings.pos.endings.multiple_b");
        }
        let action_string = this.$i18n.t(`settings.pos.${action}_count`);
        return `${action_string} ${poses_count} ${count_end}`;
      }
      return "";
    },
    render_count_string() {
      let string_array = [];
      if (this.poses.some((x) => x.action === "delete")) {
        string_array.push(this.render_count("delete"));
      }
      if (this.poses.some((x) => x.action === "create")) {
        string_array.push(this.render_count("create"));
      }
      return string_array.length > 0
        ? `${this.$i18n.t("settings.pos.warning")} 
        ${string_array.join(" " + this.$i18n.t("settings.pos.and") + " ")}!`
        : "";
    },
  },
};
</script>

<style scoped>
.settings__pos-status {
  color: red;
}
.settings__pos-status--link {
  text-decoration: underline;
  cursor: pointer;
}
.settings__row--pos {
  justify-content: flex-start;
  align-items: flex-end;
  gap: 20px;
  margin-bottom: 25px;
}

.settings__btn-pos {
  margin-top: 20px;
}
</style>
