var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"body settings"},[_c('div',{staticClass:"settings__title-row"},[_c('div',{staticClass:"settings__title settings-title"},[_vm._v(" "+_vm._s(_vm.$t("settings.settings"))+" ")]),_c('div',{staticClass:"settings__mail"},[_vm._v(_vm._s(_vm.mail))])]),_c('settings-passwords'),(_vm.$auth.check({ name: 'admin' }))?[_c('div',{staticClass:"separator"}),_c('settings-cities')]:_vm._e(),(_vm.$auth.check({ name: 'admin' }))?[_c('div',{staticClass:"separator"}),_c('settings-pos',{attrs:{"id":"pos"}})]:_vm._e(),(
      _vm.$auth.check({ permissions: 'hub_settings' }) ||
      _vm.$auth.check({ name: 'admin' })
    )?_c('div',{staticClass:"separator"}):_vm._e(),(
      _vm.$auth.check({ permissions: 'hub_settings' }) ||
      _vm.$auth.check({ name: 'admin' })
    )?_c('settings-hub'):_vm._e(),(
      _vm.$auth.check({ permissions: 'hub_settings' }) ||
      _vm.$auth.check({ name: 'admin' })
    )?_c('div',{staticClass:"separator"}):_vm._e(),(
      _vm.$auth.check({ permissions: 'hub_settings' }) ||
      _vm.$auth.check({ name: 'admin' })
    )?_c('settings-upload'):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }