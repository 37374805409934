<template>
  <div class="check-block">
    <div
      class="check-block__title"
      :class="{ 'check-block__title_small': small }"
    >
      <div class="check-block__title-text">
        {{ title
        }}<popup-hint class="hint-icon" v-if="hint">
          <div v-html="hint_text"></div>
        </popup-hint>
      </div>
      <span class="check-block__info" :class="{ error: error }">{{
        error_text
      }}</span>
    </div>
    <div
      v-for="option in options"
      :key="option.value"
      class="check-block__row"
      :class="{ 'check-block__row_small': small }"
    >
      <label
        ><input
          class="check-block__input"
          v-model="val"
          :value="option.value"
          type="radio"
          :name="option.id"
        /><span class="check-block__text">{{ option.text }}</span></label
      >
    </div>
  </div>
</template>

<script>
import PopupHint from "@/components/recurringComponents/popupHint";
export default {
  name: "customCheckBlock",
  props: {
    value: [String, Number],
    id: String,
    title: String,
    options: Array,
    hint: Boolean,
    hint_text: String,
    error: Boolean,
    error_text: String,
    small: Boolean,
  },
  components: { PopupHint },
  data() {
    return {};
  },
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        this.$emit("change");
      },
    },
  },
};
</script>

<style scoped></style>
