<template>
  <div>
    <form @submit.prevent="apply">
      <div class="settings__row">
        <div>
          <span class="settings__pass-title settings-title">{{
            $t("settings.password.change")
          }}</span>
          <custom-pass-field
            ref="new_pass"
            v-model="new_pass"
            :placeholder="$t('settings.password.new_password')"
            class="settings__pass-field"
            :rules="[rules.require, rules.password]"
          ></custom-pass-field>
          <custom-pass-field
            v-model="conf_pass"
            :placeholder="$t('settings.password.confirm_password')"
            class="settings__pass-field-confirm"
            :invalid="info.error"
            :eye="false"
          ></custom-pass-field>
        </div>
        <div class="settings__policy">
          <span>{{ $t("settings.password.policy_title") }}</span>
          <ul class="settings__policy-items">
            <li
              class="settings__policy-item"
              v-for="item in $t('settings.password.policy')"
              :key="item"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </div>

      <div class="settings__btn-row">
        <loader v-if="info.loader"></loader>
        <custom-btn v-else class="button button_theme_blue" type="submit">{{
          $t("settings.password.apply")
        }}</custom-btn>
        <span
          class="text-fields-block__info-text text_color_green"
          :class="{ text_color_red: info.error }"
          >{{ info_text }}</span
        >
      </div>
    </form>
  </div>
</template>

<script>
import CustomPassField from "@/components/recurringComponents/customPassField";
import CustomBtn from "@/components/recurringComponents/customBtn";
import rules from "@/rules";
import Loader from "@/components/recurringComponents/loader";

export default {
  name: "settingsPasswords",
  components: { Loader, CustomBtn, CustomPassField },
  data() {
    return {
      info: {
        error: false,
        type: "",
        loader: false,
      },
      rules: rules,
      new_pass: "",
      conf_pass: "",
    };
  },
  computed: {
    info_text() {
      switch (this.info.type) {
        case "required":
          return this.$i18n.t("errors.required");
        case "confirm":
          return this.$i18n.t("settings.password.loader.confirm");
        case "wrong_password":
          return this.$i18n.t("settings.password.loader.wrong_password");
        case "no_password":
          return this.$i18n.t("settings.password.loader.no_requirements");
        case "progress":
          return this.$i18n.t("settings.password.loader.progress");
        case "failed":
          return this.$i18n.t("settings.password.loader.failed");
        case "success":
          return this.$i18n.t("settings.password.loader.success");
        default:
          return "";
      }
    },
  },
  methods: {
    apply() {
      if (this.validate()) {
        this.info.loader = true;
        this.info.type = "progress";

        this.$store
          .dispatch("update_password", this.new_pass)
          .then(() => {
            this.info.type = "success";
            this.new_pass = "";
            this.conf_pass = "";
          })
          .catch(() => {
            this.info.error = true;
            this.info.type = "failed";
          })
          .finally(() => {
            this.info.loader = false;
          });

        // setTimeout(() => {
        //   this.info.loader = false;
        //   this.info.type = "success";
        // }, 2000);
      }
    },
    validate() {
      this.info.error = false;
      this.info.type = "";

      if (this.new_pass !== this.conf_pass) {
        this.info.error = true;
        this.info.type = "wrong_password";
      }

      if (this.conf_pass === "") {
        this.info.error = true;
        this.info.type = "confirm";
      }

      for (let key in this.$refs) {
        let valid = this.$refs[key].validate();
        if (!valid.valid) {
          this.info.error = true;
          this.info.type = valid.error;
        }
      }

      return !this.info.error;
    },
  },
};
</script>

<style scoped></style>
