<template>
  <div class="table">
    <div class="table__header">
      <div
        class="table__cell table__header-item"
        v-for="item in header_items"
        :key="item.name"
        :class="item.name"
        :style="item.name === active_name ? active_style : ''"
        @click="
          $emit('change_sorted', item.name);
          set_active_style(item.name);
        "
      >
        <span class="table__header-title">{{ item.title }}</span>
        <div class="table__header-arrows">
          <div
            class="table__header-arrow table__header-top-arrow"
            :class="{
              'table__header-arrow_pressed': item.sorted === 1,
            }"
          ></div>
          <div
            class="table__header-arrow table__header-bottom-arrow"
            :class="{
              'table__header-arrow_pressed': item.sorted === -1,
            }"
          ></div>
        </div>
      </div>
      <div
        class="table__cell table__header-item accounts-table__img-header"
      ></div>
    </div>
    <div class="table__data">
      <template v-for="item in items">
        <div class="table__row" :class="row_color(item.action)" :key="item.id">
          <div v-if="show_change_item !== item" class="table-2__cells">
            <template v-for="cell in Object.keys(item)">
              <div
                class="table__cell"
                :key="cell"
                v-if="show_cell(cell)"
                @click="set_active_style(cell)"
                :class="cell"
                :style="cell === active_name ? active_style : ''"
              >
                <div class="table__cell-text">{{ item[cell] }}</div>
              </div>
            </template>
            <div class="table__cell table-2__img-cell accounts-table__img-cell">
              <edit-btn @click="set_show_change_item(item)"></edit-btn>
              <delete-btn @click="delete_item(item)"></delete-btn>
            </div>
          </div>
          <div class="" v-else>
            <settings-pos-change-block
              :value="item"
              @cancel="close_change_block"
            ></settings-pos-change-block>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import EditBtn from "@/components/recurringComponents/editBtn";
import DeleteBtn from "@/components/recurringComponents/deleteBtn";
import SettingsPosChangeBlock from "./settingsPosChangeBlock.vue";

export default {
  name: "settingsPosTable",
  components: { DeleteBtn, EditBtn, SettingsPosChangeBlock },
  props: {
    header_items: Array,
    items: Array,
  },
  data() {
    return {
      tableKey: 0,
      show_confirm: false,
      being_delete: null,
      delete: false,
      show_change_item: null,
      active_name: "",
      active_style: "",
    };
  },
  methods: {
    row_color(action) {
      switch (action) {
        case "create":
          return "new_row";
        case "delete":
          return "delete_row";
        default:
          return "";
      }
    },
    show_cell(item) {
      return this.header_items.find((header) => header.name === item);
    },
    delete_item(item) {
      this.being_delete = item;
      this.$emit("delete", item);
    },
    set_show_change_item(item) {
      if (this.show_change_item) {
        this.close_change_block();
      }
      setTimeout(() => {
        this.show_change_item = item;
      }, 1);
    },
    close_change_block() {
      this.show_change_item = null;
    },
    set_active_style(name) {
      let active_width = 0;
      let fn = false;

      let collection = document.getElementsByClassName(name);
      for (let item of collection) {
        if (
          item.scrollWidth > item.clientWidth &&
          item.scrollWidth > active_width
        ) {
          active_width = item.scrollWidth;
          fn = true;

          item.addEventListener("transitionend", this.set_overflow);
        }
      }

      if (fn) {
        this.active_name = name;
        this.active_style = `flex: 0 0 ${active_width}px;`;
      }
    },

    set_overflow() {
      this.active_style += "overflow: visible";
    },
  },
};
</script>

<style scoped>
.new_row {
  background: #ebfde5;
}

.delete_row {
  background: #e5e5e5;
}
</style>
