<template>
  <div>
    <div class="settings-mode__row">
      <custom-text-field
        v-model="host"
        :placeholder="$t('settings.upload.host')"
        class="settings-mode__field"
        @change="refresh"
      ></custom-text-field>
    </div>
    <div class="settings-mode__row">
      <custom-number-field
        v-model="port"
        :placeholder="$t('settings.upload.port')"
        min="22"
        max="65500"
        class="settings-mode__field"
        @change="refresh"
      ></custom-number-field>
    </div>
    <div class="settings-mode__row">
      <custom-text-field
        v-model="user"
        :placeholder="$t('settings.upload.user')"
        class="settings-mode__field"
        @change="refresh"
      ></custom-text-field>
    </div>
    <div class="settings-mode__row">
      <custom-pass-field
        v-model="password"
        :placeholder="$t('settings.upload.password')"
        class="settings-mode__field"
        @change="refresh"
      ></custom-pass-field>
    </div>
  </div>
</template>

<script>
import CustomTextField from "@/components/recurringComponents/customTextField";
import CustomPassField from "@/components/recurringComponents/customPassField";
import CustomNumberField from "@/components/recurringComponents/customNumberField";

export default {
  name: "settingSftp",
  props: ["value"],
  components: {
    CustomTextField,
    CustomNumberField,
    CustomPassField,
  },
  data() {
    return {
      host: this.value.host,
      port: this.value.port,
      user: this.value.username,
      password: this.value.password,
    };
  },
  methods: {
    refresh() {
      this.$emit("input", {
        host: this.host,
        port: this.port,
        username: this.user,
        password: this.password,
      });
    },
  },
};
</script>

<style scoped></style>
